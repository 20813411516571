<template>
  <v-row class="my-0 pa-0 my-md-2 pa-md-2">
    <v-col cols="12" class="col-md-10 mx-auto pa-0 pa-md-2">
      <v-card class="mx-auto">
        <v-row no-gutters color="gray" class="ma-0" align="center">
          <v-img :src="img" alt="imagen_defecto" max-height="200" v-if="img">
            <div style="display: grid; align-content: end; height: 100%">
              <v-row no-gutters style="background-color: #ffffff80" align="end">
                <v-card-title class="headline no-romper-palabras">{{
                  data.nombre
                }}</v-card-title>
                <v-card-subtitle>
                  {{ data.pueblo }}
                  <span v-if="data.pueblo">,</span>
                  {{ data.cp }}
                </v-card-subtitle>
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="favorito()"
                        v-if="es_favorito"
                        color="primary"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="favorito()"
                        v-else
                      >
                        <v-icon>mdi-heart-outline</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="es_favorito">Eliminar de favoritos </span>
                    <span v-else> Añadir a favoritos </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="siguiendo()"
                        v-if="es_siguiendo"
                        color="primary"
                      >
                        <v-icon>mdi-bookmark</v-icon>
                      </v-btn>
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="siguiendo()"
                        v-else
                      >
                        <v-icon>mdi-bookmark-outline</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="es_siguiendo">Dejar de seguir este lugar</span>
                    <span v-else>Seguir este lugar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <svg
                          class="booking-logo"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 85.074 86.074"
                        >
                          <path
                            fill="#273b7d"
                            d="M23.075 53.232H12.328V40.377c0-2.74 1.054-4.215 3.414-4.5h7.376a8.01 8.01 0 0 1 8.64 8.661c0 5.48-3.31 8.745-8.64 8.745zM12.307 18.48V15.1c0-2.95 1.264-4.383 4.004-4.552h5.52c4.72 0 7.586 2.824 7.586 7.586 0 3.582-1.94 7.797-7.376 7.797H12.35zm24.53 12.855l-1.94-1.096 1.686-1.475c1.98-1.686 5.268-5.52 5.268-12.117C41.852 6.533 34.013 0 21.895 0H6.512C2.903.124.032 3.07 0 6.68V64h22.17c13.487 0 22.17-7.334 22.17-18.713 0-6.11-2.803-11.38-7.544-14.014"
                          />
                          <path
                            fill="#499fdd"
                            d="M47.837 56.308c0-4.278 3.435-7.734 7.692-7.734a7.74 7.74 0 0 1 7.734 7.734c0 4.278-3.456 7.734-7.734 7.734s-7.692-3.456-7.692-7.734"
                          />
                        </svg>
                      </v-btn>
                    </template>
                    Buscar alojamiento cercano en Booking
                  </v-tooltip>
                </v-card-actions>
              </v-row>
            </div>
          </v-img>
          <v-row
            v-else
            style="height: 100%; min-height: 100px"
            align="end"
            no-gutters
          >
            <v-card-title class="headline">{{ data.nombre }}</v-card-title>
            <v-card-subtitle>
              {{ data.pueblo }}
              <span v-if="data.pueblo">,</span>
              {{ data.cp }}
            </v-card-subtitle>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="favorito()"
                    v-if="es_favorito"
                    color="primary"
                  >
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="favorito()"
                    v-else
                  >
                    <v-icon>mdi-heart-outline</v-icon>
                  </v-btn>
                </template>
                <span v-if="es_favorito">Eliminar de favoritos </span>
                <span v-else> Añadir a favoritos </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="siguiendo()"
                    v-if="es_siguiendo"
                    color="primary"
                  >
                    <v-icon>mdi-bookmark</v-icon>
                  </v-btn>
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="siguiendo()"
                    v-else
                  >
                    <v-icon>mdi-bookmark-outline</v-icon>
                  </v-btn>
                </template>
                <span v-if="es_siguiendo">Dejar de seguir este lugar</span>
                <span v-else>Seguir este lugar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-on="on" v-bind="attrs">
                    <svg
                      class="booking-logo"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 85.074 86.074"
                    >
                      <path
                        fill="#273b7d"
                        d="M23.075 53.232H12.328V40.377c0-2.74 1.054-4.215 3.414-4.5h7.376a8.01 8.01 0 0 1 8.64 8.661c0 5.48-3.31 8.745-8.64 8.745zM12.307 18.48V15.1c0-2.95 1.264-4.383 4.004-4.552h5.52c4.72 0 7.586 2.824 7.586 7.586 0 3.582-1.94 7.797-7.376 7.797H12.35zm24.53 12.855l-1.94-1.096 1.686-1.475c1.98-1.686 5.268-5.52 5.268-12.117C41.852 6.533 34.013 0 21.895 0H6.512C2.903.124.032 3.07 0 6.68V64h22.17c13.487 0 22.17-7.334 22.17-18.713 0-6.11-2.803-11.38-7.544-14.014"
                      />
                      <path
                        fill="#499fdd"
                        d="M47.837 56.308c0-4.278 3.435-7.734 7.692-7.734a7.74 7.74 0 0 1 7.734 7.734c0 4.278-3.456 7.734-7.734 7.734s-7.692-3.456-7.692-7.734"
                      />
                    </svg>
                  </v-btn>
                </template>
                Buscar alojamiento cercano en Booking
              </v-tooltip>
            </v-card-actions>
          </v-row>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters class="ma-0 pa-4 text-justify">
          <v-card-text v-html="data.descripcion"></v-card-text>
        </v-row>
        <v-row class="ma-0 pa-4 mb-4">
          <v-col cols="12" class="col-md-6" v-if="carousel.srcs.length > 0">
            <v-carousel
              :show-arrows-on-hover="carousel.srcs.length == 1 ? false : true"
              cycle
              progress-color="primary"
            >
              <v-carousel-item
                v-for="(item, index) in carousel.srcs"
                :key="index"
                @click="
                  carousel.img = item;
                  carousel.shown = true;
                "
                :src="item"
                @error="() => errorImg(item)"
              >
              </v-carousel-item>
            </v-carousel>
            <v-dialog
              v-model="carousel.shown"
              content-class="overflow_visible"
              width="unset"
            >
              <div style="position: relative">
                <v-btn
                  @click="carousel.shown = false"
                  small
                  fab
                  absolute
                  top
                  right
                  color="white"
                  ><v-icon color="primary">mdi-close</v-icon></v-btn
                >
                <img
                  style="max-height: 85vh; max-width: 100%; object-fit: contain"
                  :src="carousel.img"
                  alt="imagen_carousel"
                />
              </div>
            </v-dialog>
          </v-col>
          <v-col v-else cols="12" class="col-md-6">
            <v-sheet
              height="100%"
              class="d-flex align-center justify-center text-center"
            >
              <v-sheet>
                <span>No hay fotos de este lugar disponibles</span>
              </v-sheet>
            </v-sheet>
          </v-col>
          <v-col cols="12" class="col-md-6" v-if="!loading">
            <Map
              style="height: 100%; min-height: 500px"
              :center="{ lat: coords.lat, lng: coords.lng }"
              :zoom="10"
              :passedMarker="{
                latitude: coords.lat,
                longitude: coords.lng,
                nombre: data.nombre,
              }"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="webShareApiSupported"
          no-gutters
          align="center"
          class="pa-4"
        >
          <v-btn icon @click="share">
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
          <span class="body-2">¿Te ha gustado? ¡Compartelo!</span></v-row
        >
        <template v-if="$store.getters.getToken != null">
          <v-row
            no-gutters
            class="pa-4 pb-0"
            align="start"
            style="margin: 0 auto"
          >
            <span class="text-h6">Deja tu comentario</span>
          </v-row>
          <v-form @submit.prevent="sendComment" class="pa-0 ma-0">
            <v-row class="pa-4" align="start" style="margin: 0 auto">
              <v-col>
                <v-textarea
                  v-model="comment.text"
                  rows="1"
                  no-resize
                  auto-grow
                  full-width
                  dense
                  filled
                  label="Comentario"
                ></v-textarea>
              </v-col>
              <v-col cols="auto" class="mx-n2">
                <v-file-input
                  v-model="comment.file"
                  @change="previewImg"
                  class="mx-n2"
                  filled
                  hide-input
                  hide-details
                  dense
                  accept=".png, .jpg, .jpeg"
                  ref="file_input"
                ></v-file-input>
              </v-col>
              <v-col cols="11" class="col-sm-auto" style="margin: 0 auto">
                <v-btn
                  :block="
                    ['xs'].includes($vuetify.breakpoint.name) ? true : false
                  "
                  type="submit"
                  color="primary"
                  >Enviar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col v-show="comment.img" cols="12">
              <v-row no-gutters align="end">
                <v-img
                  max-width="300"
                  max-height="300"
                  :src="comment.img"
                  alt="imagen_comentario"
                ></v-img>
                <v-btn
                  @click.prevent="(comment.file = null), (comment.img = null)"
                  text
                  color="primary"
                  >Eliminar imágen</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </template>
        <v-row no-gutters v-else class="pa-4">
          <v-btn to="/login" color="primary" block
            >Inicia sesión para comentar</v-btn
          >
        </v-row>
        <v-divider></v-divider>
        <v-row
          justify="center"
          align="center"
          class="pa-4"
          v-if="loading_comments"
          style="background: #f1f1f1"
          no-gutters
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" v-if="comments.length > 0" class="col-md-9">
            <template
              v-for="(comment, key) in comments.filter(
                (comment) => comment.id_parent == 0
              )"
            >
              <Comment
                :key="comment.id_comentario"
                :id_lugar="Number(comment.id_lugar)"
                :id_comentario="Number(comment.id_comentario)"
                :comment="comment.comentario"
                :id_usuario="Number(comment.id_usuario)"
                :img_src="comment.direccion_imagen || null"
                :liked="comment.liked ? true : false"
                :nombre="comment.nombre"
                :avatar_src="comment.avatar"
                :fecha="comment.escrito_en"
                :noReplyControls="!$store.getters.isLoggedIn"
                :denunciado="comment.denunciado ? true : false"
                :noFlag="comment.id_usuario == $store.getters.getUserId"
                @replied="getComments"
                @updateComments="getComments"
              />
              <template
                v-for="comment2 in comments
                  .filter(
                    (comment2) => comment2.id_parent == comment.id_comentario
                  )
                  .slice(0, comment.maxReplies)"
              >
                <Comment
                  :key="Number(comment2.id_comentario)"
                  :id_lugar="Number(comment.id_lugar)"
                  :id_comentario="Number(comment2.id_comentario)"
                  :comment="comment2.comentario"
                  :id_usuario="Number(comment2.id_usuario)"
                  :img_src="comment2.direccion_imagen || null"
                  :isReply="true"
                  noReplyControls
                  :liked="comment2.liked ? true : false"
                  :nombre="comment2.nombre"
                  :avatar_src="comment2.avatar"
                  :fecha="comment2.escrito_en"
                  :denunciado="comment2.denunciado ? true : false"
                  :noFlag="comment2.id_usuario == $store.getters.getUserId"
                  @updateComments="getComments"
                />
              </template>

              <v-btn
                :key="key"
                text
                small
                color="primary"
                v-if="
                  comments.filter(
                    (comment2) => comment2.id_parent == comment.id_comentario
                  ).length > comment.maxReplies
                "
                style="margin-left: 5%"
                @click="comment.maxReplies += 5"
                ><v-icon small>mdi-arrow-down</v-icon>Mostrar más</v-btn
              >
              <v-btn
                :key="key"
                text
                small
                color="primary"
                v-else-if="
                  comments.filter(
                    (comment2) => comment2.id_parent == comment.id_comentario
                  ).length > 2
                "
                style="margin-left: 5%"
                @click="comment.maxReplies = 2"
                ><v-icon small>mdi-arrow-up</v-icon>Mostrar menos</v-btn
              >
            </template>
          </v-col>
          <v-col v-else cols="11" class="col-md-8">
            <v-card-title>Sé el primero en comentar</v-card-title>
          </v-col>
          <v-col cols="11" class="col-md-3 ads">
            <Anuncio class="ads" :text="''" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" class="col-md-2">
      <Anuncio />
    </v-col>
    <v-col cols="12" class="mt-4" v-if="lugaresCercanos.length">
      <span class="text-h6">Otros lugares cercanos a "{{ data.nombre }}"</span>
      <div class="d-flex flex-wrap">
        <Lugar
          v-for="l in lugaresCercanos"
          :key="Number(l.id)"
          :id="Number(l.id_old)"
          :nombre="l.nombre"
          :cp="Number(l.cp)"
          :img_src="l.imagen_defecto"
          class="align-self-stretch grid"
        >
          <v-chip
            class="distancia"
            pill
            color="primary"
            v-text="
              calcDistance({ latitude: l.latitude, longitude: l.longitude })
            "
          >
          </v-chip>
        </Lugar>
      </div>
    </v-col>
    <v-col cols="12" class="mt-4" v-else>
      <span class="text-h6"
        >No se han encontrado lugares cercanos a "{{ data.nombre }}"</span
      >
    </v-col>
  </v-row>
</template>
    
<script>
import Map from "@/components/Map.vue";
import Comment from "@/components/Comment.vue";
import Anuncio from "@/components/Anuncio.vue";
import axios from "redaxios";
import Lugar from "@/components/Lugar.vue";

export default {
  components: { Comment, Map, Anuncio, Lugar },
  data() {
    return {
      loading: true,
      data: {},
      coords: {
        lat: { type: Number },
        lng: { type: Number },
      },
      carousel: {
        srcs: [],
        shown: false,
        img: null,
      },
      comments: [],
      loading_comments: true,
      comment: {
        text: "",
        file: null,
        img: null,
      },
      es_favorito: false,
      es_siguiendo: false,
      lugaresCercanos: [],
    };
  },
  computed: {
    img() {
      let r;
      try {
        r = `/static/lugares_copia/${this.data.imagen_defecto}`;
      } catch {
        r = null;
      }
      return r;
    },
    webShareApiSupported: () => navigator.share,
  },
  methods: {
    sendComment() {
      if (
        this.comment.text == "" &&
        (this.comment.file == "" || this.comment.file == null)
      ) {
        this.$root.$emit(
          "snack",
          "Tienes que escribir algo o enviar una imagen"
        );
        return;
      }
      let formData = new FormData();
      formData.append("direccion_imagen", this.comment.file);
      formData.append("token", this.$store.getters.getToken);
      formData.append("id_lugar", this.data.id);
      formData.append(
        "comentario",
        this.comment.text != "" ? this.comment.text : ""
      );
      formData.append("id_parent", 0);
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/comentarios.php`,
        data: formData,
      }).then(() => {
        this.comment = { text: "", file: null, img: null };
        this.$root.$emit("snack", "El comentario ha sido enviado");
        this.loading_comments = true;
        setTimeout(this.getComments(), 1000);
        this.loading_comments = false;
      });
    },
    getComments() {
      axios({
        url: `${process.env.VUE_APP_API_URL}/comentarios.php`,
        params: {
          id_lugar: this.data.id,
          token: this.$store.getters.getToken,
        },
        method: "GET",
      })
        .then((res) => {
          // console.table(res.data);
          if (res.data.constructor.name !== "Array" || res.data.length <= 0)
            return;
          this.comments = res.data.map((c) => {
            let fechas = c.escrito_en.split("-");
            c.escrito_en = `${fechas[2].slice(0, 2)}-${fechas[1]}-${fechas[0]}`;
            if (c.id_parent == 0) c.maxReplies = 2;
            return c;
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.loading_comments = false));
    },
    previewImg(e) {
      if (e) {
        this.comment.img = URL.createObjectURL(e);
      }
    },
    favorito() {
      if (this.$store.getters.getToken == null) {
        this.$root.$emit(
          "snack",
          "Tienes que iniciar sesión para añadir un lugar a favoritos"
        );
        return;
      }
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`,
        data: {
          id_lugar: this.data.id,
          token: this.$store.getters.getToken,
          accion: this.es_favorito ? "borrar" : "agregar",
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Z-Key",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        },
      })
        .then(() => {
          this.es_favorito = !this.es_favorito;
          if (this.es_favorito) {
            this.$root.$emit(
              "snack",
              "Se ha añadido este lugar a tus favoritos"
            );
          } else {
            this.$root.$emit(
              "snack",
              "Se ha eliminado este lugar de tus favoritos"
            );
          }
        })
        .catch((err) => console.error(err));
    },
    siguiendo() {
      if (this.$store.getters.getToken == null) {
        this.$root.$emit(
          "snack",
          "Tienes que iniciar sesión para seguir este lugar"
        );
        return;
      }
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/siguiendo.php`,
        data: {
          id_lugar: this.data.id,
          token: this.$store.getters.getToken,
          accion: this.es_siguiendo ? "borrar" : "agregar",
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(() => {
          this.es_siguiendo = !this.es_siguiendo;
          if (this.es_siguiendo) {
            this.$root.$emit("snack", "Ahora estás siguiendo este lugar");
          } else {
            this.$root.$emit("snack", "Ya no sigues este lugar");
          }
        })
        .catch((err) => console.error(err));
    },
    getFavorito() {
      if (this.$store.getters.getToken == null) return;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/favoritos.php`,
        params: {
          id_lugar: this.data.id,
          token: this.$store.getters.getToken,
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => {
          if (res.data.length > 0) this.es_favorito = true;
        })
        .catch((err) => console.error(err));
    },
    getSiguiendo() {
      if (this.$store.getters.getToken == null) return;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/siguiendo.php`,
        params: {
          id_lugar: this.data.id,
          token: this.$store.getters.getToken,
        },
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => {
          if (res.data.length > 0) this.es_siguiendo = true;
        })
        .catch((err) => console.error(err));
    },
    getInfo() {
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/lugares.php`,
          params: {
            id_lugar: this.$route.params.id,
          },
        })
          .then((res) => {
            this.data = res.data[0];
            this.coords.lat = parseFloat(this.data.latitude.replace(",", "."));
            this.coords.lng = parseFloat(this.data.longitude.replace(",", "."));
            this.loading = false;
            resolve();
          })
          .catch(() => {
            this.$router.push({ name: 404 });
            reject();
          });
      });
    },
    getImages() {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/imagenes.php`,
        params: {
          id_lugar: this.$route.params.id,
        },
      })
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            this.carousel.srcs.push(
              `/static/lugares_copia/${res.data[i].NUEVONOMBRE}`
            );
          }
          this.data2 = res.data[0];
        })
        .catch((err) => console.error(err));
    },
    share() {
      navigator.share({
        title: `TurismoyReligion | ${this.data.nombre}`,
        text: "Ven a ver este lugar en TurismoyReligion",
        url: `https://pruebas.rutasreligiosas.com/lugar/${this.$route.params.id}`,
      });
    },
    errorImg(src) {
      this.carousel.srcs.splice(
        this.carousel.srcs.findIndex((i) => i == src),
        1
      );
      this.carousel.srcs.push(require("@/assets/no_disponible.png"));
    },
    getLugaresCercanos() {
      axios({
        methods: "GET",
        url: `${process.env.VUE_APP_API_URL}/lugares.php`,
        params: {
          lat: this.coords.lat,
          lng: this.coords.lng,
          id: this.$route.params.id,
        },
      })
        .then((res) => {
          this.lugaresCercanos = res.data;
        })
        .catch((err) => console.error(err));
    },
    calcDistance({ latitude, longitude }) {
      let degreesToRadians = (deg) => (deg * Math.PI) / 180;
      const earthRadiusKm = 6371;

      let { lat, lng } = this.coords;

      let dLat = degreesToRadians(latitude - lat);
      let dLon = degreesToRadians(longitude - lng);

      lat = degreesToRadians(lat);
      latitude = degreesToRadians(latitude);

      let a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat) * Math.cos(latitude);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let km = parseFloat(earthRadiusKm * c).toFixed(2);
      return km >= 1 ? `${km} km` : `${km * 1000} m`;
    },
  },
  async mounted() {
    await this.getInfo();
    this.getComments();
    this.getFavorito();
    this.getSiguiendo();
    this.getImages();
    this.getLugaresCercanos();

    let recientes = sessionStorage.getItem("lugaresRecientes");
    recientes = typeof recientes === "string" ? recientes.split(",") : [];
    if (
      recientes[recientes.length - 1] != this.$route.params.id ||
      recientes.length == 0
    ) {
      sessionStorage.setItem(
        "lugaresRecientes",
        [...recientes, this.$route.params.id].reverse().slice(0, 10)
      );
    }
  },
};
</script>
    
<style>
.ads {
  background: url("https://www.traveldailymedia.com/assets/2015/02/booking-.-com.jpg");
  background-size: contain;
}

.booking-logo {
  width: 24px;
  height: 24px;
  transform: translate(17%, 10%);
}

.overflow_visible {
  overflow: visible;
}

.distancia {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
</style>